export const GroupArrayOfObjects = (data, key) => {
  let categoryWiseData = data.reduce((acc, posts) => {
    let category = posts.categories.nodes[0].name
    let data = posts
    return [
      ...acc,
      {
        id: category,
        data: data,
      },
    ]
  }, [])

  let groupedCategoryArr = categoryWiseData.reduce(function (acc, currVal) {
    ;(acc[currVal[key]] = acc[currVal[key]] || []).push(currVal)
    return acc
  }, {})

  return groupedCategoryArr
}

export function removeLastDirectoryPartOf(the_url) {
  let the_arr = the_url.split("/")
  the_arr.pop()
  the_arr.pop()
  return the_arr.join("/")
}

export function createPathWithCategories(originalPath, categoryUrl, slug) {
  let tempUrl = removeLastDirectoryPartOf(originalPath)
  if (!slug) {
    return tempUrl + "/" + categoryUrl + "/"
  } else return tempUrl + "/" + categoryUrl + "/" + slug + "/"
}

export function parseUrl(url) {
  return url
    .toLowerCase()
    .split(" ")
    .filter(e => e !== "&")
    .join("-")
}

export function destructureUrls(url) {
  let removeDash = url.split("-").join(" ")
  return removeDash
}
