/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import '../styles/global.css'
import SearchComponent from "./SearchComponent"
import Header from "./header"
import Footer from "./footer"
require('typeface-pt-sans')

const Layout = ({ children, includeSearchBar = true}) => {
	
  	return (
    	<>
      		{/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
			<Header />
			{includeSearchBar ? <SearchComponent/> : null}
			<main className="main-wrapper">
				{children}
			</main>
			<Footer />
    	</>
  	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
