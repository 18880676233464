import React, {useState} from "react"
import { Link } from "gatsby"

function SearchComponent() {
  const [seachInput, setSeachInput] = useState()

  const onClickHandler = () => {
    localStorage.setItem("search-input", seachInput)
  }

  const onChangeSearchHandler = e => {
    setSeachInput(e.target.value)
  }

  return (
    <div>
		<div className="bg-indigo-100 relative top-search-form">
			<div className="container flex items-center px-6 pt-32 py-20 pb-10">
				<div className="max-w-4xl mx-auto text-center relative w-full">
					<div className="flex justify-center items-center">
						<img src="https://res.cloudinary.com/vantagecircle/image/upload/v1641552946/gatsbycms/uploads/2022/01/rewards-watermark.png" />
					</div>
					<div className="absolute top-0 md:top-7 w-full">
						<h1 className="text-white text-2xl md:text-5xl mb-8 font-bold">
							Right Place for all your Queries
						</h1>
						<p className="text-white mb-10">
							Explore and know more about Rewards, Perks, Pulse and Fit.
						</p>
						<div className="md:flex justify-center relative sm:w-full">
							<input
								placeholder="Search.."
								style={{ paddingLeft: "3rem" }}
								className="md:w-7/12 w-4/6 mr-2 vc-text-input pl-44"
								type="text"
								onChange={onChangeSearchHandler}
							></input>
							<svg
								className="searchbar absolute w-5"
								viewBox="0 0 56.966 56.966"
							>
								<path
								d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0 0 46.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 0 0 .083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"
								fill="#808080"
								></path>
							</svg>
							<div className="mt-2 md:mt-0 cursor-pointer">
								<Link
								to="/search/"
								onClick={onClickHandler}
								className="vc-colored-btn"
								>
								Search
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default SearchComponent
