import React, { useState } from "react";
// import { Link } from 'gatsby'

function Footer() {
	return (
        <>
        <section class="bottom-cta m-6 md:m-0 relative z-1">
            <div class="container max-w-4xl bg-purple-300 rounded-lg shadow-xl p-8 relative z-9 overflow-hidden md:-bottom-10 xl:-bottom-20">
                <div class="bottom-cta-content text-center text-white">
                    <div class="section-title-white">Still have queries?</div>
                    <div class="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                        <div className="text-center">
                            <a class="vc-ghost-btn-cta-banner uppercase" href="https://vantagecircle.freshdesk.com/support/tickets/new" target="_blank">Raise a ticket</a>
                            <p className="text-white text-sm mt-3">You can also write to us at <a href="mailto:support@vantagecircle.com">support@vantagecircle.com</a></p>
                        </div>
                    </div>
                </div>
                <img loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png" alt="VC white dots" width="90" height="90"/>
                <img loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg" alt="VC flower" width="150" height="150"/>
            </div>                         
        </section>
		<footer className="bg-indigo-100 md:pt-28 py-10">
            <div className="container">
                <div className="text-center mb-5">
                    <a href="https://www.vantagecircle.com/">
                        <img className="text-gray-300 hover:text-gray-300 md:text-sm px-2 mx-auto vc-logo" src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/v1608528238/blog_images/images/vantagecircle-white-300.png" alt="Vantage Circle Logo" width="300"/>
                    </a>
                </div>
                <div className="max-w-md mx-auto md:flex justify-center text-center px-6">
                    <a className="text-gray-300 hover:text-gray-300 md:text-sm px-2" href="/">Home</a>
                    <a className="text-gray-300 hover:text-gray-300 md:text-sm px-2" href="https://www.vantagecircle.com/employee-engagement-software/">Solutions</a>
                    <a className="text-gray-300 hover:text-gray-300 md:text-sm px-2" href="https://www.vantagecircle.com/pricing/">Pricing</a>
                    <a className="text-gray-300 hover:text-gray-300 md:text-sm px-2" href="https://www.vantagecircle.com/resources/">Resources</a>
                    <a className="text-gray-300 hover:text-gray-300 md:text-sm px-2" href="https://www.vantagecircle.com/contact-us/">Contact Us</a>
                </div>
            </div>
        </footer>
        </>
	);
}

export default Footer;
